import React from "react";

function Tabla({ personas, editPersona, removePersona, handleGeneratePDF }) {
  return (
    <div className="p-4 rounded-3xl bg-white/30 backdrop-blur-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-extrabold text-gray-800">
          Personas Añadidas
        </h2>
        <button
          type="button"
          onClick={handleGeneratePDF}
          className="px-4 py-2 text-lg bg-teal-600 text-white rounded-xl shadow-lg hover:bg-teal-700 focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 transition-all"
        >
          Generar PDF
        </button>
      </div>
      <div className="overflow-auto rounded-3xl bg-white/30 backdrop-blur-lg shadow-inner">
        <table className="min-w-full text-sm bg-white/10 table-auto rounded-3xl overflow-hidden">
          <thead className="bg-amber-600/20 text-gray-800">
            <tr>
              <th className="border-b border-gray-200 px-4 py-2">
                Fecha Emisión
              </th>
              <th className="border-b border-gray-200 px-4 py-2">DNI</th>
              <th className="border-b border-gray-200 px-4 py-2">Nombres</th>
              <th className="border-b border-gray-200 px-4 py-2">Apellidos</th>
              <th className="border-b border-gray-200 px-4 py-2">
                Código Proyecto
              </th>
              <th className="border-b border-gray-200 px-4 py-2">
                Motivo Traslado
              </th>
              <th className="border-b border-gray-200 px-4 py-2">
                Destino <br /> (Punto de Salida - Punto de Llegada)
              </th>
              <th className="border-b border-gray-200 px-4 py-2">
                Monto en S/.
              </th>
              <th className="border-b border-gray-200 px-4 py-2">Firma</th>
              <th className="border-b border-gray-200 px-4 py-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {personas.map((persona, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-white/20" : "bg-amber-100/20"
                } text-gray-700`}
              >
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.fechaEmision}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.dni}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.nombres}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.apellidos}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.codigoProyecto}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.motivoTraslado}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.destino1} - {persona.destino2}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.montoSoles}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  {persona.signatureImage && (
                    <img
                      src={persona.signatureImage}
                      alt="Firma"
                      className="h-8 mx-auto"
                    />
                  )}
                </td>
                <td className="border-b border-gray-200 px-4 py-2">
                  <button
                    type="button"
                    onClick={() => editPersona(index)}
                    className="mr-2 px-2 py-1 bg-yellow-500/90 text-white rounded-md shadow-md hover:bg-yellow-600/90 transition-all"
                  >
                    Editar
                  </button>
                  <button
                    type="button"
                    onClick={() => removePersona(index)}
                    className="px-2 py-1 bg-red-500/90 text-white rounded-md shadow-md hover:bg-red-600/90 transition-all"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Tabla;
