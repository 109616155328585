import React, { useState } from "react";
import axios from "axios";
import "jspdf-autotable";
import "tailwindcss/tailwind.css";
import GeneratePDF from "./GeneratePdf";
import Tabla from "./Tabla";
import SearchIcon from "./Search";
import { InputField, SelectField } from "./form/Form";
import Loader from "./Loader";
import empresas from "./Empresas";

const MobilityForm = () => {
  const [formData, setFormData] = useState({
    empresa: "INACONS S.R.L.",
    fechaInicio: "",
    fechaFin: "",
  });
  const [personas, setPersonas] = useState([]);
  const [currentPerson, setCurrentPerson] = useState({
    fechaEmision: "",
    dni: "",
    nombres: "",
    apellidos: "",
    codigoProyecto: "",
    motivoTraslado: "",
    destino1: "",
    destino2: "",
    montoSoles: "",
    signatureImage: null,
  });
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para manejar el loader

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePersonChange = (e) => {
    const { name, value } = e.target;
    setCurrentPerson((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignatureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        alert("Por favor, sube una imagen en formato PNG o JPG.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentPerson((prevState) => ({
          ...prevState,
          signatureImage: reader.result,
        }));
        setSignaturePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const addPersona = () => {
    console.log(currentPerson);
    if (
      !currentPerson.fechaEmision ||
      !currentPerson.dni ||
      !currentPerson.nombres ||
      !currentPerson.apellidos ||
      !currentPerson.codigoProyecto ||
      !currentPerson.motivoTraslado ||
      !currentPerson.destino1 ||
      !currentPerson.destino2 ||
      !currentPerson.montoSoles
    ) {
      alert(
        "Por favor, completa todos los campos antes de añadir a la persona."
      );
      return;
    }

    setPersonas((prevState) => [...prevState, currentPerson]);

    setCurrentPerson({
      fechaEmision: "",
      dni: currentPerson.dni,
      nombres: currentPerson.nombres,
      apellidos: currentPerson.apellidos,
      codigoProyecto: "",
      motivoTraslado: "",
      destino1: "",
      destino2: "",
      montoSoles: "",
      signatureImage: currentPerson.signatureImage,
    });
    setSignaturePreview(null);
  };

  const editPersona = (index) => {
    const personToEdit = personas[index];
    setCurrentPerson(personToEdit);
    setSignaturePreview(personToEdit.signatureImage || null);
    removePersona(index);
  };

  const removePersona = (index) => {
    setPersonas((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleGeneratePDF = async () => {
    if (personas.length === 0) {
      alert("Por favor, añade al menos una persona antes de generar el PDF.");
      return;
    }
    setLoading(true); // Mostrar loader

    // Usar setTimeout para forzar la actualización del estado
    setTimeout(async () => {
      try {
        await GeneratePDF(formData, personas);
      } finally {
        setLoading(false); // Ocultar loader
      }
    }, 100); // Pequeño retraso para permitir que la UI se actualice
  };

  const searchByDNI = async () => {
    if (!currentPerson.dni) {
      alert("Por favor, ingresa un DNI para buscar.");
      return;
    }
    setLoading(true); // Mostrar loader
    try {
      const response = await axios.get(
        `https://intranet.rcmoviles.com/.php/zClientesController.php?tipoDocumento=dni&numeroDocumento=${currentPerson.dni}`
      );
      const data = response.data[0];
      if (data) {
        setCurrentPerson((prevState) => ({
          ...prevState,
          nombres: data.nameOrRS,
          apellidos: `${data.apPaterno} ${data.apMaterno}`,
        }));
      } else {
        alert("No se encontraron datos para este DNI.");
      }
    } catch (error) {
      console.error("Error al realizar la búsqueda:", error);
      alert("Hubo un error en la búsqueda. Intenta nuevamente.");
    } finally {
      setLoading(false); // Ocultar loader
    }
  };

  const getSelectedCompanyImage = () => {
    const selectedCompany = empresas.find(
      (empresa) => empresa.name === formData.empresa
    );
    return selectedCompany ? selectedCompany.imagen : null;
  };

  return (
    <div className="flex flex-wrap justify-between px-4 pt-4 bg-gray-200 ">
      {loading && <Loader />}

      {/* Formulario */}
      <div className="w-full lg:w-1/4 p-6 bg-amber-200 backdrop-blur-md rounded-2xl shadow-lg">
        <div className="h-16 flex justify-center items-center mb-6">
          <img
            src={getSelectedCompanyImage()}
            alt="Imagen de la empresa seleccionada"
            className="w-50 h-auto"
          />
        </div>

        <form autoComplete="off" className="space-y-6 bg-white/50 p-6 rounded-xl shadow-xl backdrop-blur-md">
          <SelectField
            label="Empresa"
            name="empresa"
            value={formData.empresa}
            onChange={handleChange}
            options={empresas.map((empresa) => ({
              value: empresa.name,
              label: empresa.name,
            }))}
          />

          <div className="grid grid-cols-2 gap-3.5">
            <InputField
              label="Fecha Inicial"
              name="fechaInicio"
              type="date"
              value={formData.fechaInicio}
              onChange={handleChange}
            />
            <InputField
              label="Fecha Fin"
              name="fechaFin"
              type="date"
              value={formData.fechaFin}
              onChange={handleChange}
            />
          </div>

          <h2 className="text-lg font-semibold text-gray-800 mt-4 pt-4">
            Añadir Persona
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="flex items-center relative">
              <InputField
                label="DNI"
                name="dni"
                value={currentPerson.dni}
                onChange={handlePersonChange}
                placeholder="Ingresa el DNI"
              />
              <button
                type="button"
                onClick={searchByDNI}
                className="absolute right-2 bottom-0 transform translate-x-1/2 translate-y-1/2 bg-amber-500 text-white rounded-full p-0.5 shadow-sm hover:bg-amber-700 focus:ring-2 focus:ring-offset-2 focus:ring-amber-400"
              >
                <SearchIcon />
              </button>
            </div>

            <InputField
              label="Nombres"
              name="nombres"
              value={currentPerson.nombres}
              onChange={handlePersonChange}
              placeholder="Nombres"
            />
            <InputField
              label="Apellidos"
              name="apellidos"
              value={currentPerson.apellidos}
              onChange={handlePersonChange}
              placeholder="Apellidos"
            />
            <InputField
              label="Fecha Emisión"
              name="fechaEmision"
              type="date"
              classNamed="text-amber-600"
              value={currentPerson.fechaEmision}
              onChange={handlePersonChange}
            />
            <InputField
              label="Código del Proyecto"
              name="codigoProyecto"
              classNamed="text-amber-600"
              value={currentPerson.codigoProyecto}
              onChange={handlePersonChange}
              placeholder="Ingresa el código"
            />
            <InputField
              label="Motivo del Traslado"
              name="motivoTraslado"
              classNamed="text-amber-600"
              value={currentPerson.motivoTraslado}
              onChange={handlePersonChange}
              placeholder="Ingresa el motivo"
            />
            <InputField
              label="Punto de Salida"
              name="destino1"
              classNamed="text-amber-600"
              value={currentPerson.destino1}
              onChange={handlePersonChange}
              placeholder="Ingresa el destino de Salida"
            />
            <InputField
              label="Punto de Llegada"
              name="destino2"
              classNamed="text-amber-600"
              value={currentPerson.destino2}
              onChange={handlePersonChange}
              placeholder="Ingresa el destino de Llegada"
            />
            <InputField
              label="Monto en Soles"
              name="montoSoles"
              type="number"
              classNamed="text-amber-600"
              value={currentPerson.montoSoles}
              onChange={handlePersonChange}
              placeholder="Ingresa el monto"
            />
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Firma (PNG o JPG)
              </label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleSignatureUpload}
                className="mt-1 block w-full text-gray-700"
              />
              {signaturePreview && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Vista previa de la firma:
                  </p>
                  <img
                    src={signaturePreview}
                    alt="Vista previa de la firma"
                    className="h-24 mt-1 border rounded-lg"
                  />
                </div>
              )}
            </div>
          </div>

          <button
            type="button"
            onClick={addPersona}
            className="mt-6 w-full px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow-md hover:bg-green-600 focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Añadir Persona
          </button>
        </form>
      </div>

      {/* Tabla */}
      <div className="w-full lg:w-3/4 p-6 bg-orange-200 backdrop-blur-md rounded-2xl shadow-lg">
        <Tabla
          personas={personas}
          editPersona={editPersona}
          removePersona={removePersona}
          handleGeneratePDF={handleGeneratePDF}
        />
      </div>
    </div>
  );
};

export default MobilityForm;
