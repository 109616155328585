import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo1 from "./logo1.png";
import logo2 from "./logo2.png";
import logo3 from "./logo3.png";
import logo4 from "./logo4.png";
import logo5 from "./logo5.png";

// Lista de empresas con sus respectivos logos y datos
const empresas = [
  {
    name: "INACONS S.R.L.",
    ruc: "20568587767",
    imagen: logo1,
  },
  {
    name: "INACONS S.A.C.",
    ruc: "20607713368",
    imagen: logo2,
  },
  {
    name: "GELTECH S.A.C.",
    ruc: "20605665251",
    imagen: logo3,
  },
  {
    name: "NUFAGO S.A.C.",
    ruc: "20606360453",
    imagen: logo4,
  },
  {
    name: "VELIMAQ S.A.C.",
    ruc: "20605665269",
    imagen: logo5,
  },
];

const GeneratePDF = (formData, personas) => {
  const doc = new jsPDF("landscape");

  // Buscar la empresa seleccionada
  const empresaSeleccionada = empresas.find(
    (empresa) => empresa.name === formData.empresa
  );

  // Datos por defecto si no se selecciona ninguna empresa
  const logoEmpresa = empresaSeleccionada ? empresaSeleccionada.imagen : logo1;
  const razonSocial = empresaSeleccionada
    ? empresaSeleccionada.name
    : "Empresa no seleccionada";
  const rucEmpresa = empresaSeleccionada ? empresaSeleccionada.ruc : "---";

  const formatFecha = (fecha) => {
    if (!fecha) return "";
    const [year, month, day] = fecha.split("-");
    return `${day}/${month}/${year.slice(-2)}`;
  };

  // Añadir el logo de la empresa seleccionada
  doc.addImage(logoEmpresa, "PNG", 14, 10, 70, 20);

  // Encabezado del documento
  doc.setFontSize(12);
  doc.text("PLANILLA DE GASTOS DE MOVILIDAD", 54, 38, { align: "center" });

  // Información del formato
  doc.setFontSize(10);
  doc.setDrawColor(0);
  doc.setFillColor(255, 255, 255);
  doc.rect(doc.internal.pageSize.width - 80, 11, 64, 24, "FD");

  doc.setFontSize(12);
  doc.setFont(undefined, "bold");
  doc.text("FORMATO", doc.internal.pageSize.width - 60, 16);

  doc.setFontSize(10);
  doc.setFont(undefined, "bold");
  doc.text("Código:", doc.internal.pageSize.width - 78, 22);
  doc.text("Versión:", doc.internal.pageSize.width - 78, 27);
  doc.text("Fecha de Aprobación:", doc.internal.pageSize.width - 78, 32);

  doc.setFontSize(10);
  doc.setFont(undefined, "normal");
  doc.text("FO-ADM-017", doc.internal.pageSize.width - 39, 22);
  doc.text("3", doc.internal.pageSize.width - 39, 27);
  doc.text("22/08/2024", doc.internal.pageSize.width - 39, 32);

  // Datos de la empresa y período
  doc.autoTable({
    body: [
      [
        {
          content: "Razón Social:",
          styles: { fontStyle: "bold", fillColor: [200, 200, 200] },
        },
        razonSocial,
        "",
        {
          content: "Número:",
          styles: { fontStyle: "bold", fillColor: [200, 200, 200] },
        },
        formData.numero,
      ],
      [
        {
          content: "Ruc:",
          styles: { fontStyle: "bold", fillColor: [200, 200, 200] },
        },
        rucEmpresa,
        {
          content: "Gastos de movilidad realizados en el periodo del:",
          colSpan: 2,
          styles: { fontStyle: "bold", fillColor: [200, 200, 200] },
        },
        `${formatFecha(formData.fechaInicio)} al ${formatFecha(
          formData.fechaFin
        )}`,
      ],
    ],
    startY: 44,
    theme: "plain",
    styles: { cellPadding: 1, fontSize: 10 },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 60 },
      2: { cellWidth: 100 },
      3: { cellWidth: 20 },
      4: { cellWidth: 50 },
    },
  });

  // Preparar el cuerpo de la tabla con las firmas
  const body = [];

  personas.forEach((persona, index) => {
    body.push([
      formatFecha(persona.fechaEmision || ''),
      persona.dni || '',
      `${persona.nombres || ''} ${persona.apellidos || ''}`,
      persona.codigoProyecto || '',
      persona.motivoTraslado || '',
      `${persona.destino1 || ''} - ${persona.destino2 || ''}`,
      persona.montoSoles || '',
      { content: '', image: persona.signatureImage } // Usamos un objeto con content vacío e image
    ]);
  });

  // Rellenar con filas vacías hasta completar al menos 10 filas
  while (body.length < 10) {
    body.push(["", "", "", "", "", "", "", ""]);
  }

  // Añadir la tabla principal
  doc.autoTable({
    head: [['Fecha Emisión', 'Documento de Identidad', 'Nombres y Apellidos', 'Código Proyecto', 'Motivo del Traslado', 'Destino\n(Punto de Salida - Punto de Llegada)', 'Monto en S/.', 'Firma']],
    body: body,
    startY: doc.previousAutoTable.finalY + 8,
    theme: 'grid',
    headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0], fontStyle: 'bold' },
    styles: { halign: 'center', valign: 'middle', fontSize: 8, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 25 },
      1: { cellWidth: 30 },
      2: { cellWidth: 40 },
      3: { cellWidth: 25 },
      4: { cellWidth: 40 },
      5: { cellWidth: 40 },
      6: { cellWidth: 25 },
      7: { cellWidth: 40 }
    },
    didDrawCell: (data) => {
      // Si la columna es la de firma y existe una imagen, la dibuja
      if (data.column.index === 7 && data.cell.raw && data.cell.raw.image) {
        const img = data.cell.raw.image;
        const dim = data.cell.height - 2;
        doc.addImage(img, 'PNG', data.cell.x + 5, data.cell.y + 1, dim * 2, dim);
      }
    }
  });

  // Calcular el total de montos en soles
  const totalSoles = personas.reduce(
    (total, persona) => total + parseFloat(persona.montoSoles || 0),
    0
  );

  // Añadir fila del total
  doc.autoTable({
    head: [["", "", "", "", "", "", "", ""]],
    body: [
      [
        {
          content: "TOTAL",
          colSpan: 6,
          styles: {
            halign: "right",
            fontStyle: "bold",
            fillColor: [200, 200, 200],
          },
        },
        {
          content: `S/. ${totalSoles.toFixed(2)}`,
          styles: { halign: "center", fontStyle: "bold" },
        },
        "",
      ],
    ],
    startY: doc.previousAutoTable.finalY,
    theme: "plain",
    styles: { fontSize: 8 },
    columnStyles: {
      0: { cellWidth: 20 },
      1: { cellWidth: 20 },
      2: { cellWidth: 20 },
      3: { cellWidth: 20 },
      4: { cellWidth: 20 },
      5: { cellWidth: 30 },
      6: { cellWidth: 30 },
      7: { cellWidth: 38.5 },
    },
  });

  // Guardar el documento
  doc.save(`Planilla_Movilidad_${personas[0]?.dni || "sin_dni"}.pdf`);
};

export default GeneratePDF;
