import MobilityForm from "./components/UIX/MobilityForm";

function App() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-slate-900 via-teal-400 to-slate-900 h-20 flex items-center justify-center shadow-lg backdrop-blur-lg bg-opacity-60 border-b border-white/30">
        <h1 className="text-white text-center text-xl md:text-4xl font-extrabold uppercase tracking-wide drop-shadow-lg">
          Formulario Planilla de Movilidad
        </h1>
      </header>

      {/* Main Content */}
      <main className="flex-grow shadow-lg">
        <div className="bg-white/60 rounded-2xl shadow-xl backdrop-blur-md ">
          <MobilityForm />
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 text-center py-4 shadow-inner">
        <p>&copy; 2024 Velimaq | Todos los derechos reservados</p>
      </footer>
    </div>
  );
}

export default App;
