// Importación de los logos
import logo1 from "./logo_inacons.svg";
import logo2 from "./logonuevo_ INACONS.svg";
import logo3 from "./logo_geltech.svg";
import logo4 from "./logo_nufago.svg";
import logo5 from "./logo_velimaq.svg";

const empresas = [
    { name: "INACONS S.R.L.", ruc: "20568587767", imagen: logo1 },
    { name: "INACONS S.A.C.", ruc: "20607713368", imagen: logo2 },
    { name: "GELTECH S.A.C.", ruc: "20605665251", imagen: logo3 },
    { name: "NUFAGO S.A.C.", ruc: "20606360453", imagen: logo4 },
    { name: "VELIMAQ S.A.C.", ruc: "20605665269", imagen: logo5 },
  ];

  export default empresas