const InputField = ({ label, name, value, onChange, type = "text", placeholder = "", classNamed  }) => (
  <div className="-mb-3">
    <label className={`${classNamed} block text-sm font-medium text-gray-700`}>{label}</label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="block w-full bg-white/50 border border-gray-300 rounded-lg shadow-md focus:ring-indigo-500 focus:border-indigo-500 backdrop-blur-md transition ease-in-out duration-200 text-xs placeholder-opacity-50"
      style={{ fontSize: "0.8rem", paddingLeft: "1rem", padding: "0.2rem" }}
      autoComplete="offimatic" // Desactiva el almacenamiento de datos históricos
    />
  </div>
);




// Componente para selector
const SelectField = ({ label, name, value, onChange, options, classNamed }) => (
  <div className="-mb-3">
    <label className={`${classNamed} block text-sm font-medium text-gray-700`}>{label}</label>
    <select
      autoComplete="off"
      name={name}
      value={value}
      onChange={onChange}
      className="block w-full bg-white/50 border border-gray-300 rounded-lg shadow-md focus:ring-indigo-500 focus:border-indigo-500 backdrop-blur-md transition ease-in-out duration-200"
    >
      <option value="">Selecciona una opción</option>
      {options.map((option, i) => (
        <option key={i} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export { InputField, SelectField };
